<template>
	<div class="box">
		<top-nav backImage bgColor="#fff" @back="back"><span class="title">保障计划详情</span></top-nav>
		<div class="bg_fff">
			<div class="detail_title">{{ product.name }}</div>
			<ul>
				<li class="li_row">
					<div class="left">承保年龄</div>
					<div class="right">{{ product.insureAge }}</div>
				</li>
				<li class="li_row">
					<div class="left">职业分类</div>
					<div class="right">1-4类</div>
				</li>
				<li class="li_row">
					<div class="left">承保区域</div>
					<div class="right">中国境内（不含港澳台地区）</div>
				</li>
				<li class="li_row">
					<div class="left">医院范围</div>
					<div class="right" v-text="product.featureOne"></div>
				</li>
				<li class="li_row">
					<div class="left">免赔及给付<br/>比例说明:</div>
					<div class="right" v-text="product.featureTwo"></div>
				</li>
				<li class="li_row">
					<div class="left">保障期限</div>
					<div class="right">{{product.insureTime}}（投保成功后次日零时生效）</div>
				</li>
			</ul>
			<div class="info_tip">本处未尽事宜，以本产品适用的保险条款、保险合同约定为准。</div>
		</div>
		<!-- 健康管理服务 -->
		<div class="bg_fff">
			<div class="detail_title">健康管理服务</div>
			<div class="service_content">
				<p>尊敬的客户：</p>
				<p>您好!</p>
				<p class="indent-2">本保险产品所涵盖的所有健康管理服务内容，包括服务内容和申请流程。购买本产品即视为您同意我公司将您的相关信息提供给第三方服务提供商。</p>
				<p class="mt_4">本服务手册所涉及的服务指定服务商上海金仕达卫宁科技，如有疑问可致电客服电话: 4008002150，工作时间为9:00-17:30，含节假日。</p>
				<p class="mt_4">具体内容详见<span class="color_main" @click="handlePopupHealth">《健康管理服务手册》</span></p>
			</div>
		</div>
		<!--  报案及理赔-->
		<div class="mt_2 bg_fff">
			<div class="detail_title">报案及理赔</div>
			<div class="service_content">
				<p v-html="product.compensationHint"></p>
			</div>
		</div>
		<!-- 温馨提示 -->
		<div class="mt_2 bg_fff">
			<div class="detail_title">温馨提示</div>
			<div class="service_content">
				<p>1.本保险产品由中国人寿财产保险股份有限公司承保 (即本投保须知所称“国寿财险”)，国寿财险总部设立于北京，我公司在全国除港澳台之外的所有省、自治区及直辖市均设有分公司。本产品面向全国大陆地区销售 (不含港澳台地区)</p>
				<p class="mt_4">2.公司偿付能力披露信息: http://www.chinalife-p.com.cn/chinalifeproperty/gkxxpl/zxxx/cfnl/index.html，我公司偿付能力充足率符合监管要求。</p>
			</div>
		</div>


	</div>
</template>

<script>
export default {
	data() {
		return {
			product:{},
			
		};
	},
	methods: {
		back() {
			this.$router.go(-1);
		},
		handlePopupHealth(){
			window.open(this.product.link)
		}
	},
	created () {
		this.product=this.$route.params.productDetail
	},

};
</script>

<style lang="scss" scoped>
.box {
	background-color: $b_border_color;
}
.bg_fff {
	background-color: #fff;
}
.title {
	color: #000 !important;
}
.detail_title {
	font-size: 0.28rem;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.8);
	padding: 0.2rem 0.4rem;
	border-bottom: 0.02rem solid #e8eaec;
}
.li_row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0.2rem 0.4rem;
	font-size: 0.28rem;
	border-bottom: 0.02rem solid #e8eaec;
	.left {
		font-weight: 500;
		color: rgba(0, 0, 0, 0.8);
	}
	.right {
		width: 4.4rem;
		font-size: 0.24rem;
		color: rgba(0, 0, 0, 0.6);
		text-align: right;
	}
}
.info_tip {
	background-color: $b_border_color;
	padding: 0.2rem 0.4rem;
	font-size: 0.2rem;
	color: rgba(0, 0, 0, 0.6);
}
.service_content {
	padding: 0.2rem 0.4rem;
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.24rem;
	box-sizing: border-box;
	overflow: hidden;
}
.indent_2 {
	text-indent: 0.4rem;
}
.mt_4 {
	margin-top: 0.4rem;
}
.color_main {
	color: $color_main;
}
.mt_2 {
	margin-top: 0.2rem;
}
</style>
